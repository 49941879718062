<template>
<div>
  <BaseMap/>
</div>
</template>

<script>
import BaseMap from '@/components/BaseMap.vue'
export default {
  name: 'home-view',
  components: {
    BaseMap
  },
  computed: {
    isSignedIn () {
      return this.$store.getters.isSignedIn
    }
  }
}
</script>
