
import { API } from 'aws-amplify'

export default {
  getGridCells () {
    return API.get('gsi-scan-and-get-data-lambda', '/Prod/GetGridDDB').then(result => {
      return result
    })
  },
  getLocations () {
    return API.get('gsi-scan-and-get-data-lambda',
      '/Prod/GetHexagons'
    ).then(result => {
      return result
    })
  }
}
