import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import Amplify, * as AmplifyModules from 'aws-amplify'

Vue.config.productionTip = false

Vue.use(AmplifyModules)
Vue.use(BootstrapVue)
Vue.component('v-icon', Icon)

const oauth = {
  // Domain name
  domain: process.env.VUE_APP_OAUTH_DOMAIN,
  // Authorized scopes
  scope: ['email', 'openid', 'profile'],
  // Callback URL
  redirectSignIn: window.location.origin,
  // Sign out URL
  redirectSignOut: window.location.origin,
  // 'code' for Authorization code grant,
  // 'token' for Implicit grant
  responseType: 'code',
  // optional, for Cognito hosted ui specified options
  options: {
    // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
    AdvancedSecurityDataCollectionFlag: false
  }
}

Amplify.configure({
  Auth: {
    region: 'eu-west-1',
    userPoolId: process.env.VUE_APP_COGNITO_USERPOOL,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_APP_CLIENT,
    oauth: oauth
  },
  Analytics: {
    disabled: true
  },
  API: {
    endpoints: [
      {
        name: 'gsi-scan-and-get-data-lambda',
        endpoint: process.env.VUE_APP_APIGW_ENDPOINT,
        region: 'eu-west-1',
        custom_header: async () => {
          return {
            Authorization: (await AmplifyModules.Auth.currentSession()).getIdToken().getJwtToken()
          }
        }
      }
    ]
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
