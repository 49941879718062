<template>
  <div>
    <b-button v-if="!isSignedIn" variant="outline-dark" @click="onLoginClick()">Sign in</b-button>
    <b-button v-if="isSignedIn" variant="outline-success" @click="onLogoutClick()">Sign out</b-button>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'
export default {
  methods: {
    onLoginClick () {
      Auth.federatedSignIn()
    },
    onLogoutClick () {
      Auth.signOut()
        .then(data => {
          this.$store.commit('setSignedIn', false)
        })
        .catch(err => {
          console.log('signout error: ', err)
        })
    }
  },
  computed: {
    isSignedIn () {
      return this.$store.getters.isSignedIn
    }
  }

}
</script>
