<template>
  <div>

    <div id="overlay" v-if='!isParsedAndLoadedData && isSignedIn'>
      <b-spinner id="loader" type="grow" label="Loading..." v-if='!isParsedAndLoadedData && isSignedIn'></b-spinner>
    </div>
    <h5 id='loadingHeader' class='loadingBar' v-if='isParsedAndLoadedData'>Latest positions have been added to the map</h5>
    <h5 id='privacyNotice' class='loadingBar' v-if='isParsedAndLoadedData'>FOR INTERNAL USE ONLY</h5>
    <div style="position: absolute; left: 50%;">
      <div id='totalVehicles' v-if='isParsedAndLoadedData'>
        <span id="totalVehiclesValue" class="block">{{ totalVehicles.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}</span>
        <span id="totalVehiclesText" class="block">connected vehicles</span>
      </div>
    </div>
    <div id='layerButtons' v-if='isParsedAndLoadedData' role="group">
      <div id='baseStyleButtons'>
        <b-form-group>
          <b-form-radio-group
            id="base-style-choice"
            v-model="selectedBaseLayer"
            name="base-style-choice"
          >
            <b-form-radio value="scania">Scania</b-form-radio>
            <b-form-radio value="light-v10">Light</b-form-radio>
            <b-form-radio value="outdoors-v11">Bright</b-form-radio>
            <b-form-radio value="satellite-v9">Satellite</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </div>
      <div id='dataLayerButtons'>
        <b-button-group>
          <b-button v-for="(btn, idx) in buttons"
                    :key="idx" variant='light'
                    @click="setLayerFunction( btn.switchVariable ); activeBtn = btn.switchVariable"
                    :class="{active: activeBtn === btn.switchVariable }">{{ btn.caption }}</b-button>
        </b-button-group>
      </div>
    </div>
    <div id='mapContainer' class='basemap'> </div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import GeoJSON from 'geojson'
import { h3ToGeo } from 'h3-js'

export default {
  name: 'BaseMap',
  data () {
    return {
      buttons: [
        { caption: 'Cluster', state: true, switchVariable: 'cluster' },
        { caption: 'Heatmap', state: false, switchVariable: 'heatmap' },
        { caption: 'Points', state: false, switchVariable: 'points' }
      ],
      activeBtn: 'cluster',
      accessToken: process.env.VUE_APP_MAP_TOKEN,
      positions: null,
      hexes: null,
      selectedGeoJson: [
        { caption: 'Active now', state: false, switchVariable: 'activeNow' }
      ],
      activeSource: 'activeNow',
      geoJSONData: {},
      mapObject: null,
      loading: false,
      srcName: 'grid_cell_source',
      srcPoints: 'grid_cell_points',
      selectedBaseLayer: 'scania',
      layerNames: {
        clusteredIdName: { layerName: 'grid-cell-layer', visible: 'visible' },
        unclusteredIdName: { layerName: 'unclustered-grid-cell-layer', visible: 'visible' },
        clusteredLabelsIdName: { layerName: 'cluster-labels', visible: 'visible' },
        unclusteredLabelsIdName: { layerName: 'unclustered-labels', visible: 'visible' },
        heatmapIdName: { layerName: 'heatmap-grid-cell-layer', visible: 'none' },
        heatmapBaseLayer: { layerName: 'glow-base-layer', visible: 'none' },
        heatmapSecondLayer: { layerName: 'glow-second-layer', visible: 'none' },
        heatmapTopLayer: { layerName: 'glow-third-layer', visible: 'none' }
      },
      totalVehicles: 0
    }
  },

  mounted () {
    this.createMap().finally(() => {
      this.updateMapWithSources()
      this.updateMapWithData()
    })
  },

  methods: {
    showSelectedDataLayers () {
      for (let dataLayer in this.layerNames) {
        if (this.layerNames[dataLayer].visible === 'none') {
          this.setLayerToHide(this.layerNames[dataLayer])
        } else {
          this.setLayerToShow(this.layerNames[dataLayer])
        }
      }
    },

    setLayerToShow (layer) {
      this.mapObject.setLayoutProperty(layer.layerName, 'visibility', 'visible')
      layer.visible = 'visible'
    },

    setLayerToHide (layer) {
      this.mapObject.setLayoutProperty(layer.layerName, 'visibility', 'none')
      layer.visible = 'none'
    },

    switchBaseLayer () {
      return new Promise(resolve => {
        // Get source and layer to add them back after the style change, since the map's style object is the one holding the layers and the respective filters, etc.
        let layer = this.selectedBaseLayer
        if (layer === 'scania') {
          this.mapObject.setStyle('mapbox://styles/connected-intelligence/ckhpx27eb02zt19qrm3qphq46')
        } else {
          this.mapObject.setStyle('mapbox://styles/mapbox/' + layer)
        }
        this.mapObject.on('style.load', _ => {
          resolve()
        })
      })
    },

    setLayerFunction (layer) {
      switch (layer) {
        case 'heatmap':
          // Hide all data layers
          for (let mapDataLayerToHide in this.layerNames) {
            this.setLayerToHide(this.layerNames[mapDataLayerToHide])
          }

          // Show only heatmap layer
          this.setLayerToShow(this.layerNames['heatmapIdName'])

          break

        case 'cluster':
          // Show all data layers
          for (let mapDataLayerToShow in this.layerNames) {
            this.setLayerToShow(this.layerNames[mapDataLayerToShow])
          }

          // Hide heatmap layer
          this.setLayerToHide(this.layerNames['heatmapIdName'])
          this.setLayerToHide(this.layerNames['heatmapBaseLayer'])
          this.setLayerToHide(this.layerNames['heatmapSecondLayer'])
          this.setLayerToHide(this.layerNames['heatmapTopLayer'])

          break

        case 'points':
          // Hide all data layers
          for (let mapDataLayerToHide in this.layerNames) {
            this.setLayerToHide(this.layerNames[mapDataLayerToHide])
          }

          // Show only heatmap layer
          this.setLayerToShow(this.layerNames['heatmapBaseLayer'])
          this.setLayerToShow(this.layerNames['heatmapSecondLayer'])
          this.setLayerToShow(this.layerNames['heatmapTopLayer'])

          break
      }
    },

    createMap () {
      return new Promise(resolve => {
        // initialize the map
        this.mapObject = new mapboxgl.Map({
          accessToken: this.accessToken,
          container: 'mapContainer',
          style: 'mapbox://styles/connected-intelligence/ckhpx27eb02zt19qrm3qphq46',
          center: [24, 33],
          zoom: 1.62
        })
        this.mapObject.addControl(new mapboxgl.NavigationControl())
        this.mapObject.addControl(new mapboxgl.FullscreenControl())
        this.mapObject.addControl(new mapboxgl.ScaleControl())

        this.mapObject.on('style.load', _ => {
          resolve()
        })
      })
    },
    H3ToGeoJson () {
      let parsed = this.hexes.map(hex => {
        let invertedCoordinates = h3ToGeo(hex.h3Hex)
        return { vehicleCount: hex.vehicleCount, gridLatitude: invertedCoordinates[0], gridLongitude: invertedCoordinates[1] }
      })
      this.geoJSONData = GeoJSON.parse(parsed, { Point: ['gridLatitude', 'gridLongitude'], include: ['vehicleCount'] })
    },
    sumVehicleCountHexes () {
      let count = 0
      for (let i = 0; i < this.hexes.length; i++) {
        count += this.hexes[i].vehicleCount
      }
      this.totalVehicles = count
    },
    getLocations () {
      return this.$store.dispatch('getLocations').then(result => {
        this.hexes = result
        this.H3ToGeoJson()
      }).catch(err => {
        console.log('getLocations error: ', err)
      })
    },
    updateMapWithSources () {
      // Add geojson source (data with no features at this stage)
      this.mapObject.addSource(this.srcName, {
        type: 'geojson',
        data: { 'type': 'FeatureCollection', 'features': [] },
        cluster: true,
        clusterMaxZoom: 22, // Max zoom to cluster points on
        clusterRadius: 150,
        clusterProperties: {
          sum: ['+', ['get', 'vehicleCount']]
        }
      })

      // Add geojson source (data with no features at this stage)
      this.mapObject.addSource(this.srcPoints, {
        type: 'geojson',
        data: { 'type': 'FeatureCollection', 'features': [] }
      })
    },

    updateMapWithData () {
      let clusteredStyleLayer = {
        'id': this.layerNames['clusteredIdName'].layerName,
        'type': 'circle',
        'source': this.srcName,
        'layout': {
          'visibility': this.layerNames['clusteredIdName'].visible
        },
        'filter': ['==', 'cluster', true],
        'paint': {
          'circle-stroke-color': '#4cda25',
          'circle-stroke-width': 5,
          'circle-color': 'rgba(0,0,0,.6)',
          'circle-radius': [
            'step',
            ['get', 'sum'],
            20, 100,
            30, 1000,
            40, 10000,
            50, 100000,
            60
          ]
        }
      }

      let unclusteredStyleLayer = {
        'id': this.layerNames['unclusteredIdName'].layerName,
        'type': 'circle',
        'layout': {
          'visibility': this.layerNames['unclusteredIdName'].visible
        },
        'source': this.srcName,
        'filter': ['!=', 'cluster', true],
        'paint': {
          'circle-stroke-color': '#4cda25',
          'circle-stroke-width': 5,
          'circle-color': 'rgba(0,0,0,.6)',
          'circle-radius': [
            'step',
            ['get', 'vehicleCount'],
            20, 100,
            30, 1000,
            40, 10000,
            50, 100000,
            60
          ]
        }
      }

      let heatmapLayer = {
        'id': this.layerNames['heatmapIdName'].layerName,
        'type': 'heatmap',
        'source': this.srcPoints,
        'layout': {
          'visibility': this.layerNames['heatmapIdName'].visible
        },
        'paint': {
          // Increase the heatmap color weight weight by zoom level
          // heatmap-intensity is a multiplier on top of heatmap-weight
          'heatmap-weight': [
            'interpolate',
            ['linear'],
            ['get', 'vehicleCount'],
            0, 1,
            100, 2,
            1000, 5,
            10000, 10
          ],
          // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
          // Begin color ramp at 0-stop with a 0-transparancy color
          // to create a blur-like effect.
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0, 'rgba(76,218,37,0)',
            0.55, 'rgb(76,218,37)',
            0.9, 'rgb(219,247,211)',
            1.0, 'rgb(255,255,255)'
          ],
          // Adjust the heatmap radius by zoom level
          'heatmap-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0, ['max', ['log2', ['get', 'vehicleCount']], 2],
            4, ['max', ['log2', ['get', 'vehicleCount']], 10],
            9, ['max', ['log2', ['get', 'vehicleCount']], 15],
            15, ['max', ['log2', ['get', 'vehicleCount']], 25],
            20, ['max', ['log2', ['get', 'vehicleCount']], 40]
          ]
        }
      }

      let glowBaseStyleLayer = {
        'id': this.layerNames['heatmapBaseLayer'].layerName,
        'type': 'circle',
        'layout': {
          'visibility': this.layerNames['heatmapBaseLayer'].visible
        },
        'source': this.srcPoints,
        'paint': {
          'circle-blur': 0.2,
          'circle-opacity': 0.4,
          'circle-color': '#4cda25',
          'circle-radius': [
            'interpolate', ['linear'], ['zoom'],
            1, 2,
            4, ['max', ['+', ['*', 0.01, ['get', 'vehicleCount']], 2]],
            13, ['max', ['+', ['*', 0.009, ['get', 'vehicleCount']], 5]],
            22, ['max', ['+', ['*', 0.009, ['get', 'vehicleCount']], 10]]
          ]
        }
      }

      let glowSecondStyleLayer = {
        'id': this.layerNames['heatmapSecondLayer'].layerName,
        'type': 'circle',
        'layout': {
          'visibility': this.layerNames['heatmapSecondLayer'].visible
        },
        'source': this.srcPoints,
        'paint': {
          'circle-opacity': 0.4,
          'circle-color': '#39ff14',
          'circle-radius': [
            'interpolate', ['linear'], ['zoom'],
            1, 1.8,
            4, ['max', ['+', ['*', 0.007, ['get', 'vehicleCount']], 2]],
            13, ['max', ['+', ['*', 0.005, ['get', 'vehicleCount']], 5]],
            22, ['max', ['+', ['*', 0.005, ['get', 'vehicleCount']], 8]]
          ]
        }
      }

      let glowThirdStyleLayer = {
        'id': this.layerNames['heatmapTopLayer'].layerName,
        'type': 'circle',
        'layout': {
          'visibility': this.layerNames['heatmapTopLayer'].visible
        },
        'source': this.srcPoints,
        'paint': {
          'circle-color': '#ffffff',
          'circle-opacity': 1,
          'circle-radius': [
            'interpolate', ['linear'], ['zoom'],
            1, 1.5,
            4, ['max', ['+', ['*', 0.005, ['get', 'vehicleCount']], 2]],
            13, ['max', ['+', ['*', 0.003, ['get', 'vehicleCount']], 5]],
            22, ['max', ['+', ['*', 0.003, ['get', 'vehicleCount']], 8]]
          ]
        }
      }

      // Add all layers to map
      this.mapObject.addLayer(clusteredStyleLayer)
      this.mapObject.addLayer(unclusteredStyleLayer)
      this.mapObject.addLayer(heatmapLayer)

      this.mapObject.addLayer(glowThirdStyleLayer)
      this.mapObject.addLayer(glowSecondStyleLayer)
      this.mapObject.addLayer(glowBaseStyleLayer)

      this.mapObject.addLayer({
        'id': this.layerNames['clusteredLabelsIdName'].layerName,
        'type': 'symbol',
        'source': this.srcName,
        'filter': ['==', 'cluster', true],
        'layout': {
          'text-field': ['number-format', ['get', 'sum'], {}],
          'text-font': ['Montserrat Bold', 'Arial Unicode MS Bold'],
          'text-size': 13,
          'visibility': this.layerNames['clusteredLabelsIdName'].visible
        },
        'paint': {
          'text-color': '#4cda25'
        }
      })

      this.mapObject.addLayer({
        'id': this.layerNames['unclusteredLabelsIdName'].layerName,
        'type': 'symbol',
        'source': this.srcName,
        'filter': ['!=', 'cluster', true],
        'layout': {
          'text-field': ['number-format', ['get', 'vehicleCount'], {}],
          'text-font': ['Montserrat Bold', 'Arial Unicode MS Bold'],
          'text-size': 13,
          'visibility': this.layerNames['unclusteredLabelsIdName'].visible
        },
        'paint': {
          'text-color': '#4cda25'
        }
      })
    }
  },

  computed: {
    isParsedAndLoadedData () {
      return this.$store.getters.isParsedAndLoadedData
    },
    isSignedIn () {
      return this.$store.getters.isSignedIn
    },
    totalVehiclesComputed () {
      return this.totalVehicles
    }
  },
  watch: {
    '$store.state.parsedAndLoadedData': function () {
      this.mapObject.getSource(this.srcName).setData(this.geoJSONData)
      this.mapObject.getSource(this.srcPoints).setData(this.geoJSONData)
      this.sumVehicleCountHexes()
    },
    '$store.state.signedIn': function () {
      this.loading = true
      const hexCells = this.getLocations()
      Promise.all([hexCells]).then(result => {
        this.loading = false
        this.$store.commit('setParsedAndLoadedData', true)
      }).catch(err => {
        console.log('getData error: ', err)
      })
    },
    selectedBaseLayer: function () {
      this.switchBaseLayer().finally(() => {
        this.updateMapWithSources()
        this.mapObject.getSource(this.srcName).setData(this.geoJSONData)
        this.mapObject.getSource(this.srcPoints).setData(this.geoJSONData)
        this.updateMapWithData()
        this.showSelectedDataLayers()
      })
    }
  }
}
</script>

<style scoped>
.basemap {
  top: 0;
  bottom: 0;
  width: 100%;
  height: 90vh;
}
.loadingBar {
  margin-top: 10px;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
}
#loadingHeader {
  -moz-animation: cssAnimation1 0s ease-in 5s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation1 0s ease-in 5s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation1 0s ease-in 5s forwards;
  /* Opera */
  animation: cssAnimation1 0s ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
#privacyNotice {
  animation: cssAnimation2 0s 6s forwards;
  visibility: hidden;
}
#totalVehicles {
  margin-top: 79vh;
  left: -50%;
  position: relative;
  z-index: 1;
  padding-top: 8px;
  border-radius: 2px;
  height: 105px;
  width: 195px;
  background-color: rgba(0,0,0,0.4);
  color: #4cda25;
  box-shadow: 0 1px 4px grey;
  font-weight: bold;
  border: 1px solid #4cda25;
}
#totalVehiclesValue {
  margin-top: 4px;
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  color: white;
  text-shadow: 1px 1px black;
}
#totalVehiclesText {
  margin-top: -2px;
}
span.block {
  display: block;
}
@keyframes cssAnimation1 {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
  }
}
@-webkit-keyframes cssAnimation1 {
  to {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}
@keyframes cssAnimation2 {
  to {
    visibility: visible;
  }
}
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99
}
#loader {
  width: 150px;
  height: 150px;
  z-index: 100;
  color: #53FB48;
  position: relative;
  top: 40%
}
#layerButtons {
  z-index: 1;
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  padding-top: 8px;
  border-radius: 2px;
}
.btn-light {
 font-family: 'Montserrat', sans-serif;
}
#baseStyleButtons {
  background: #f8f9fa;
  color: #212529;
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
  border-radius: 2px;
}
#dataLayerButtons {
  padding: 10px;
  border-radius: 2px;
}
.form-group {
  margin: 0;
}
.geoJsonSource {
  margin-left:5px;
  font-size: 14px;
  cursor: pointer;
}
.selectedGeoJSON {
  text-decoration: underline;
  color: white;
}
</style>
