import Vue from 'vue'
import Vuex from 'vuex'
import client from 'api-client'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    signedIn: process.env.VUE_APP_API_CLIENT === 'mock',
    parsedAndLoadedData: false
  },
  mutations: {
    setSignedIn (state, signedInStatus) {
      state.signedIn = signedInStatus
    },
    setParsedAndLoadedData (state, parsedAndLoadedDataStatus) {
      state.parsedAndLoadedData = parsedAndLoadedDataStatus
    }
  },
  getters: {
    isSignedIn: state => {
      return state.signedIn
    },
    isParsedAndLoadedData: state => {
      return state.parsedAndLoadedData
    }
  },
  actions: {
    getGridCells () {
      return client.getGridCells()
    },
    getLocations () {
      return client.getLocations()
    }
  }
})
