<template>
  <div class="top-nav">
    <b-navbar toggleable="lg">
      <b-navbar-brand to="/"><span class="scania symbol"></span><b-nav-text style="margin-left: 10px;">Connected Fleet</b-nav-text></b-navbar-brand>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="isSignedIn" class="ml-auto main-navbar">
        </b-navbar-nav>
      </b-collapse>
      <loginComponent v-if="!signingIn" class="ml-auto"/>
    </b-navbar>
  </div>
</template>

<script>
import { Auth, Hub } from 'aws-amplify'
import LoginComponent from './LoginComponent'

export default {
  name: 'TheHeader',
  components: {
    LoginComponent
  },
  data () {
    return {
      signingIn: false
    }
  },
  created () {
    this.checkAuthentication()
    Hub.listen('auth', ({ payload: { event, data } }) => {
      this.signingIn = true
      this.checkAuthentication()
    })
  },
  computed: {
    isSignedIn () {
      return this.$store.getters.isSignedIn
    }
  },
  methods: {
    checkAuthentication () {
      Auth.currentAuthenticatedUser().then(user => {
        this.$store.commit('setSignedIn', true)
      }).catch(() => {
        this.$store.commit('setSignedIn', false)
      }).finally(() => {
        this.signingIn = false
      })
    }
  }
}
</script>

<style scoped>
.scania .symbol, .scania.symbol {
    vertical-align: middle;
    background-image: url('https://static.scania.com/resources/logotype/scania/scania-symbol.png');
    background-image: url('https://static.scania.com/resources/logotype/scania/scania-symbol.svg');
    background-repeat: no-repeat;
    background-size: 48px 46px;
    width: 48px;
    height: 46px;
    float: left;
}
.main-navbar {
  font-size: 1.2rem;
}
.top-nav {
  border-bottom: 1px solid #0000001a
}
a.router-link-exact-active {
  color: rgba(0, 0, 0, 0.7) !important;
}
</style>
